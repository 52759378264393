h2.contact-heading{
    background-color: coral;
    color: white;
}

.form-style{
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: 0px 20px 20px 20px;
  }
  
  .form-group{
    border-radius: 5px;
    border: solid 1px #e74c3c;
    background-color: #ffffff;
  }
  
  .form-control{
    font-size: 14px;
    border:1px solid #e74c3c;
  }
  
  .query-btn{
    border-radius: 5px;
    font-weight: bold;
    background-color: #ffffff;
    color: #e74c3c;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  
  }
  textarea#validationTextarea{
    height:200px;
  }
  
  
  .complain-btn{
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
    background-color: #e74c3c;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
  
  .submit-btn{
    width: 200px;
    border-radius: 5px;
    color:#ffffff;
    font-weight: bold;
    background-color: #e74c3c;
  }
  .contact-alternate{
    background-color: floralwhite;
    margin-top: 50px;
  }
  h2.heading.get-in-touch{
    float: left;
    margin-left: 50px;
    color: orange;
    font-family: monospace;
  }
  h3.heading.for.enquires{
    margin-top: 30px;
    float: left;
    margin-left: 50px;
    font-family: cursive;
  }
  img.mobile{
    height: 30px;
  }
  img.email{
    height: 30px; 
  }
  /* ******************************88 */
  
  
  /* Make Responsive */
    @media only screen and (max-width: 576px) {
      
  
    }
  
    @media only screen and (max-width: 768px) {
      
  
  }