h2.ride-heading{
    background-color: coral;
    height: 40px;
    color: white;
}
label.ride-sub-heading {
    font-size: xx-large;
}
h5.card-title.why-ygc{
    background-color: cadetblue;
    color: white;
}