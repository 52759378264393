img.img-fluid {
    width: 1200px;
    height: 400px;
}
h1.title-header{
    padding-top: 10px;
}
h4.title-text{
    padding-bottom: 15px;
}
p.card-text{
    font-size: 25px;
}
img.card-img-top{
    height: 200px;
}
a.btn.btn-blue{
    outline-style: auto;
}
a.btn.btn-outline-primary {
    width: 250px;
}