/* h1.display-3{
	background-color: blanchedalmond;
	color: darkcyan;
} */
/* h3{
	background-color: cornflowerblue;
	color: azure;
} */
h1.ygc-about-main-description{
	background-color: coral;
    color: white;
}
img.image-about{
	width: 1200px;
    height: 400px;
	overflow: hidden;
}

p.container.ygc-about-first-paragraph{
	font-size: 20px;
}
p.container.ygc-about-first-secondparagraph{
	font-size: 20px;
}
p.container.ygc-about-paragraph{
	font-size: 20px;
    font-style: oblique;
}
.ygc-tagline-aboutus{
	font-size: 40px;
    font-style: oblique;
    color: coral;
}
p.container.ygc-why-description{
	font-size: 20px;
    font-style: italic;
}
h3.heading-why-ygc{
	background-color: coral;
    height: 40px;
    color: white;
}
.card-body.why-ygc{
	background-color: beige;
}
/* img.image-about{
	margin-left: 170px;
} */