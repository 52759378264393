
h3.ygc-servicespage{
	background-color: coral;
	color: blanchedalmond;
	padding-bottom: 20px;
    padding-top: 20px;
}
/* p2.card-text{
	color: black;
} */
.card-body{
	height: 250px;
}
h5.card-title{
	text-decoration: underline;
	font-size: x-large;
}
p.card-text-services{
	font-size: 20px;
	font-style: oblique;
}