/* Common Style */


/* img.d-block.w-100{
	height: min-content;
	width: 100%;
}

img.d-block{
	width: -webkit-fill-available;
    height: -webkit-fill-available;
} */
/* HomeItems */
/* h3{
	background-color: bisque;
}
h1.display-3{
	background-color: blanchedalmond;
}
a.btn.btn-secondary{
	background-color: cornflowerblue;
}
.row.py-4.d-flex.align-items-center{
	background-color: cadetblue;
}
h6.mb-0{
	float: right;
} */

.tansport.ml-10.mr-3{
	margin-inline-start: auto;
}
a.nav-linkbar{
	margin-right: 20px;
}
a.nav-linkbar{
	color: red;
}
a.dropdown-item {
	color: red;
}
a.nav-linkbar{
	font-size: 18px;
}
img{
	padding-right: 0.5rem;
}
a{
	padding-left: 0.2rem;
	font-size: 18px;
}
.sticky-top{
	position: sticky
}

/* HomeItems */
/* a.btn.btn-primary.btn-lg{
	background-color: red;
} */
