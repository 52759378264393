h3.contact-heading-main{
  margin-left: 40px;
  color: #e74c3c;
}
footer.page-footer.font-small.blue.pt-4 {
    background-color: azure;
}
footer.page-footer.font-small.blue.pt-4{
  background-color: #393939;
}
h5.text-uppercase{
  color: white;
}
/* p1{
  color: white;
} */

.footer-copyright.text-center.py-3{
  color: aliceblue;
}
h4{
  color:white;
}
/* p2{
  color:white;
} */
.fa {
  padding: 5px;
  font-size: 20px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
}

.fa:hover {
    opacity: 0.7;
}
.fa-facebook {
  background: #3B5998;
  color: white;
}
.fa-instagram {
  background: #125688;
  color: white;
}
.fa-twitter {
  background: #55ACEE;
  color: white;
}
.fa-linkedin {
  background: #007bb5;
  color: white;
}
.row.py-4.d-flex.align-items-center{
  background-color: bisque;
}
p.ygc-routes{
  color:azure
}
p.ygc-footer-contact{
  color: azure;
}