.ygc-homeheading{
	font-size: 35px;
}
.ygc-subhomeheading{
	font-size: 30px;
}
h1.ygc-home-main{
	margin-bottom: 30px;
	font-size: 60px;
	color: cadetblue;
	background-color: aqua;
}
.container.ygc-subhomeheading{
	margin-bottom: 35px;
}
h1.ygc-home-main-heading{
	background-color: coral;
	font-size: 60px;
	color: white;
}
h3.home-services-heading {
	background-color: coral;
    height: 40px;
    color: white;
}
.row.justify-content-center.keep-img {
	font-size: 40px;
    font-style: oblique;
    color: coral;
}

button.inquiry.btn-iq{
	background-color: black;
    color: wheat;
    height: 40px;
    width: 100px;
}

/* h3{
	background-color: bisque;
}
h1.display-3{
	background-color: blanchedalmond;
}
a.btn.btn-secondary{
	background-color: cornflowerblue;
}
.row.py-4.d-flex.align-items-center{
	background-color: cadetblue;
}
h6.mb-0{
	float: right;
}
a.btn.btn-primary.btn-lg{
	background-color: red;
} */